import React from 'react';
import Layout from '../Layout/index';

export default function RoundedLayout({
  className,
  roundedBg,
  top,
  bottom,
  both,
  children,
}) {
  return (
    <Layout className={`${roundedBg ? roundedBg : ''} !py-0 pt-0 pb-0`}>
      <div
        className={`${
          top
            ? 'rounded-t-[60px] sm:rounded-t-[200px]'
            : bottom
            ? 'rounded-b-[60px] sm:rounded-b-[200px]'
            : both
            ? 'rounded-b-[60px] rounded-t-[60px] sm:rounded-b-[200px] sm:rounded-t-[200px]'
            : 'rounded-b-[60px] sm:rounded-b-[200px]'
        }
    ${className ? className : ''}`}
      >
        {children}
      </div>
    </Layout>
  );
}
