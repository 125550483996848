import RawHTML from '@components/Common/RawHTML/index';
import React from 'react';

const StatsPanelFSP = ({ stats, green, dark }) => {
  return (
    <div
      className={`p-4 sm:p-0 ${
        dark
          ? 'bg-v5-green-500 text-v5-neutral-200'
          : green
          ? 'bg-v5-green-100 text-black'
          : 'bg-v5-neutral-200 text-v5-neutral-400 '
      } flex flex-wrap  justify-center  rounded-[20px] sm:flex-nowrap sm:justify-between md:flex md:min-w-[680px]`}
    >
      {stats.map((el, idx) => (
        <>
          <div
            key={el.title}
            className={`my-2 flex flex-col justify-center px-2 text-center md:w-1/5`}
          >
            <h4
              className={`${
                dark ? 'text-v5-green-100' : 'text-black'
              } font-manrope font-extrabold`}
            >
              {el.title}
            </h4>
            <p className="whitespace-nowrap font-rubik text-xs leading-tight">
              <RawHTML>{el.subtitle}</RawHTML>
            </p>
          </div>
          {idx !== stats.length - 1 && (
            <div className="hidden h-full min-h-[100px] border-r border-v5-green-100 border-opacity-20 sm:block"></div>
          )}
        </>
      ))}
    </div>
  );
};

export default StatsPanelFSP;
